.spinner {
    --spinner-size: 18px;

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    margin: auto;
    width: var(--spinner-size);
    height: var(--spinner-size);
    display: block;
    overflow: hidden;
    animation: loader-animate-fade 1s linear;

    &__wrapper { animation: outer-rotate 2.91667s linear infinite; }
    &__inner {
        width: var(--spinner-size);
        height: var(--spinner-size);
        position: relative;
        will-change: transform;
        animation: sporadic-rotate 5.25s cubic-bezier(0.35,0,.25,1) infinite;
    }
    &__left,
    &__right {
        position: absolute;
        top: 0;
        height: var(--spinner-size);
        width: calc(var(--spinner-size) / 2);
        overflow: hidden;
    }
    &__left { left: 0; }
    &__right { right: 0; }
    &__circle {
        position: absolute;
        top: 0;
        width: var(--spinner-size);
        height: var(--spinner-size);
        box-sizing: border-box;
        border-width: calc(var(--spinner-size) * .11);
        border-style: solid;
        border-color: transparent;
        border-radius: 50%;
        border-top-color: var(--spinner-color);
        animation-iteration-count: infinite;
        animation-duration: 1.3125s;
        animation-timing-function: cubic-bezier(0.35,0,.25,1);

        .spinner__left & {
            left: 0;
            border-left-color: var(--spinner-color);
            animation-name: left-wobble;
        }
        .spinner__right & {
            right: 0;
            border-left-color: transparent;
            border-right-color: var(--spinner-color);
            animation-name: right-wobble;
        }
    }
}
@keyframes loader-animate-fade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes outer-rotate {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@keyframes left-wobble {
    0%,100% { transform: rotate(130deg); }
    50%     { transform: rotate(-5deg); }
}
@keyframes right-wobble {
    0%,100% { transform: rotate(-130deg); }
    50%     { transform: rotate(5deg); }
}
@keyframes sporadic-rotate {
    12.5% { transform: rotate(135deg); }
    25%   { transform: rotate(270deg); }
    37.5% { transform: rotate(405deg); }
    50%   { transform: rotate(540deg); }
    62.5% { transform: rotate(675deg); }
    75%   { transform: rotate(810deg); }
    87.5% { transform: rotate(945deg); }
    100%  { transform: rotate(1080deg); }
}
