.lineProgressBar {
    width: 100vw;
    height: 2px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 40;
    overflow: hidden;
}
.lineProgressBar::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: #0f6fff;
    animation: grow 1s ease-in-out infinite;
}

@keyframes grow {
    50% {
        width: 100%;
    }
    100% {
        width: 0;
        right: 0;
        left: unset;
    }
}