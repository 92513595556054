@charset "utf-8";

$account-menu-background: #383a3d;
$account-menu-hover: #f4f5f7;
$account-menu-link-hover: #000;
$thcolor: #808080;
$radius: 16px;
$black-silver: #F8F8F8;
.account-left-menu {
  transition: width .5s;
}
$size-small: 1rem;
$size-medium: 1.1rem;
$size-large: 1.2rem;


html {
  height: 100%;
  font-size: 14px;
  overflow-y: auto !important;
}
.main {
  min-height: 100vh;

  &.columns:not(:last-child) {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    margin: 0;
  }
  #account-sidebar-column {
    &.collapse {
      @media (min-width: 768px) {
        ~ #right-side {
          width: calc(100% - 70px);
        }
      }
    }
  }
  @media (min-width: 768px) {
    #right-side {
      width: calc(100% - 220px);
      padding: 0;
    }

    #account-sidebar-column {
      padding: 0;
    }
  }
}
.is-fixed-bottom .navbar-brand {
  padding-bottom: 5px;
}
.shop-landing .content {
  text-align: left;
}
.select-channel__control, .select-channel__indicators {
  height: 30px;
  overflow: hidden;
}

.react-confirm-alert-overlay {
  & .react-confirm-alert-body {
    width: 100%;
    max-width: 700px;
  }
  @media (max-width: 700px) {
    & .react-confirm-alert-button-group {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

.download-products-class .react-confirm-alert-body {
  width: 100%;
  max-width: 600px;
}

.select-lang a{
  text-transform: uppercase;
}
.border-right {
  border-right: 1px solid silver;
}
.mobile .subtitle {
  font-size: 1rem;
}
/*.selected-border.is-one-odd {
  border-left: 0;
}


.selected-border.is-one-even {
  border-left: 4px solid $account-menu-background;
}
*/
#account-sidebar-column.account-left-menu .sub-menu a {
  font-size: 13px;
}

.highlight {
  background-color: yellow; /* Цвет фона подсветки */
  transition: background-color 0.5s, transform 0.5s; /* Плавные переходные анимации для цвета фона и трансформации */
  animation: blink 1s infinite; /* Анимация мигания */
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.silver, .silver.label {
  color: $thcolor;
}
.is-one-64 {
  width: 64px;
  flex: none;
}
body .dropdown-content {
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
}
.selected-border, .sidebar-active {
  border-left: 4px solid #00BBF9;
  border-radius: 12px 0px 0px 12px;
}

.modal-FinishReservModal .modal-content {
  width: 500px;
}
@media (min-width: 901px) {
  .modal-factorysale-modal .modal-content{
    width: 700px;
  }

}

.columns.info-block{
  margin-left: 0;
  margin-right: 0;
}
.info-block  {
  border-radius: $radius;
  background: $black-silver;
  align-items: flex-start;
  padding: 0 16px;
  gap: 24px;
}

/*##### SALES REPORT #### */
.sales-report .table tr td, .sales-report .table tr th {
  text-align: left;
}
.table.is-striped tbody tr:not(.is-selected) table.row-tables tr.info-row {
  background-color: #fafafa;
}
.App .new-filters .title {
  text-align: left;
}
.products-filter .field.is-grouped > .control {
  display: inline-flex;
}
.search-filter.show svg {
  height: 16px;
  margin-right: 10px;
}
.number_c {
  float:right;
}
.search-filter.show .title {
  cursor: pointer;
}
.search-filter.show li {
  padding-bottom: 20px;
  /*    border-bottom: 1px solid silver;*/
  margin-bottom: 20px;
}
.channel-select.mySelect__clear-indicator {
  display: none;
}

.channel-select.mySelect__multi-value__label {
  max-width: 72%;
}

.active-select.buttons .button.iconright  {
  padding-right: 0px;
  font-size: 8px;
  padding-left: 6px;
  border-radius: 6px;
}
.active-select .button.iconright svg {
  height: 7px;
}
.button.iconright svg {
  margin-left: 10px;
  height: 12px;
}
.select-filter {
  margin-top: 15px;
}
.box.short-filter .select-filter .button {
  font-weight: 400;
  margin-right: 5px;
}

.button .silver {
  font-size: 14px;

  margin-right: 5px;
}
.is-debt {
  background: #FFF3F8;
  color: #FF1744;
  font-weight: 500;
}

.dropdown.dot {

  & .button {
    background: transparent;
  }

  & .dropdown-trigger .button svg {
    font-size: 35px;
    width: 35px;
  }

  & .icon.is-small {
    display: none;
  }
}

.table.is-striped tbody tr:not(.is-selected) table.row-tables tr:nth-child(even) {
  background-color: #fff;
}
body .sales-report .table tr td:last-child{
  text-align: center;
}
.sales-report .dropdown-trigger .icon {
  display: none;
}

.App .modal-PrintModal .modal-content{
  overflow: hidden !important;
}

.modal .print-modal .button .icon:last-child:not(:first-child){
  margin-left: 5px;
}
.modal .print-modal .dropdown .button {
  background: $black-silver;
  border-radius: $radius;
  padding: 15px;
  width: 100%;
  height: 54px;
  color:black;
}
.dropdown-trigger .button:focus:not(:active), .button.is-focused:not(:active) {
  box-shadow: none;
  border: none;
  // border: 2px solid $account-menu-hover;
}

.print-modal {
  min-height: 500px;
}

.big-dot {
  font-size: 35px;
}

.inside-sale div {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.order-status, .button.order-status.is-rounded {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 10px;
  border-radius: 6px;
  width: 156px;
  height: fit-content;
  //margin: 0 auto;
  background: #36bcff;
  white-space: normal;
}

.order-status.order-status-reserve_sale {
  background: #ddf1fa;
  color: #00BBF9;
}

.modal .modal-content .product-mobile-block .box {
  border-radius: 16px;
}
/*.mobile .select-produt-filter .column {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding: 0;
}*/
.select-produt-filter {
  margin: 0px;
  margin-bottom: 0 !important;
}
.select-produt-filter .column{
  padding-bottom: 0;
}
.green-border {
  border: 1px solid #46c37b;
}
.order-status.order-status-refund,
.button.order-status.order-status-refund,
.order-status-cancelled,
.button.order-status.order-status-cancelled {
  background: #FF1744;
  color: #fff;
}

.order-status.button.is-small {
  font-size: 1rem;
}


.order-status.order-status-on-delivering,
.order-status.order-status-delivering,
.order-status.order-status-arrived {
  &.is-info {
    background: #d6effa;
    color: #0a0809;
  }

  &.is-small {
    font-size: 0.65rem;
  }
}

.order-status.order-status-delivery,
.button.order-status.order-status-delivery,
.button.order-status.order-status-processing {
  background: #3D3D3D;
  color: #FFFFFF;
}
.order-status.order-status-cod {
  background: #ddf1fa;
  color: #00BBF9;
}

.button.order-status.order-status-saled,
.order-status.order-status-completed,
.order-status.order-status-saled,
.button.order-status.order-status-completed{
  background: #5feeaa;
  color: #000000;
}

.button.order-status.order-status-saled:hover {
  background: #66ffb6;
  color: #000000;
}

.longData {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*#### END SALES REPORT #### */

/* ######## COLLAPSE MENU ####### */
#account-sidebar-column.account-left-menu.collapse {
  width: 70px;
  z-index: 25;
  /*  overflow-x: hidden;*/
}
.account-logo {
  margin-top: 10px;
}
.account-logo-collapse {
  display: none;
}
.collapse .account-logo, .collapse .hide-menu span {
  display: none;
}

.collapse .account-logo-collapse {
  display: block;
  margin-top: 8px;
}

.collapse .menu-list li {
  position: relative;
}
.collapse .menu-list a:not(.sidebar-active){
  border-left: 4px solid transparent;
}
.collapse .menu-list a span {
  /*  opacity: 0;*/
  display: none;
}

.collapse .sub-menu {
  display: none;
}

.collapse .menu-list a span  {
  position: absolute;
  top: -2.1rem;
  z-index: 1001;
  background: white;
  color: var(--background-color);
  border-radius: 0.4rem;
  padding: 0.5rem 0.9rem;
  transition: 0s;
  font-size: 1rem;
  opacity: 0;
  display: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
#account-sidebar-column.account-left-menu.collapse .sub-menu a {
  /*    color: #000;*/
}
.collapse .menu-list li:hover .sub-menu, .collapse .menu-list .sub-menu:hover {
  display: block;
  position: absolute;
  /*    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);*/
  border-radius: 16px;
  top: -0.8rem;
  width: 290px;
  z-index: 2000;
  padding: 7px;
}



.collapse .menu-list a:hover span  {
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  opacity: 1;
  display: block;
}

.sub-title-menu {
  display: none;
}

.collapse .menu-list .has-submenu .sub-title-menu hr{
  margin:0;
  height: 1px;
}
// #account-sidebar-column.account-left-menu.collapse .menu-list .has-submenu .sub-title-menu a{
//   font-size: 20px;
// }
.collapse .menu-list .has-submenu .sub-title-menu {
  display: block;
}

.collapse .menu-list .has-submenu  a:hover span {
  display: none;
}

.wrapper-sidebar {
  //position: relative;
  height: 100%;
  position: fixed;
  background: inherit;
  width: 220px;
  transition: all 0.5s ease;
  z-index: 1;
  /*  overflow: hidden;*/
}

.collapse .wrapper-sidebar {
  width: 70px;
}

.wrapper-sidebar .menu-list .has-submenu {
  position: relative;
}

.wrapper-sidebar .menu-list .has-submenu .menu-list.sub-menu.is-closed {
  margin-top: 0;
  margin-bottom: 0;
}

.wrapper-sidebar .menu-list .has-submenu .toggle-submenu-btn {
  position: absolute;
  top: 3px;
  right: 5px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #D4D7DC;
  filter: invert(87%) sepia(3%) saturate(44%) hue-rotate(315deg) brightness(98%) contrast(84%);
  &:focus, &:active{
    border: none;
  }
}

.wrapper-sidebar .menu-list .has-submenu .toggle-submenu-btn svg {
  margin: 0;
  width: 30px;
  height: 30px;
  filter: inherit;
}

.collapse .wrapper-sidebar .menu-list .has-submenu .toggle-submenu-btn {
  display: none !important;
}


.hide-menu a{
  text-decoration: none;
}
.hide-menu {
  width: fit-content;
  text-align: left;
  position: fixed;
  bottom: 15px;
  margin-left: 20px;
}

/* ################ COLLAPSE MENU END */

.sb.button.bicon svg {
  margin-right: 6px;
}
/* ###3# FILTERS ##### */

.new-filters {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 2; /* Stay on top */
  top: 0;
  right: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s;
  padding: 0px;
  opacity: 0;
}

.new-filters.show {
  width: 350px;
  transition: 0.5s;
  padding: 20px;
  opacity: 1;
}

.box.short-filter .control .input,.box.short-filter  .button {
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.box.short-filter  .dropdown-menu{
  left: -150px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.box.short-filter .filters-date-string {
  position: absolute;
}
.box.short-filter div {
  margin-bottom: 0;
}
.box.short-filter  {
  text-align:left;
  margin-bottom: 20px;
  position: relative;
  border-radius: 0 0 14px 14px;
}
.short-filter .sd {
  display: inline-block;
  margin-right: 5px;
}
.short-filter .sdr {
  float: right;
  margin-top: 5px;
}
.short-filter .dropdown svg {
  font-size: 25px;
}

.short-filter .dropdown .button:focus{
  box-shadow: none;
}

.short-filter .dropdown .icon.is-small {
  display: none;
}

/* ###3# FILTERS END ##### */
.mobile .full-login {
  width: 100%;
}
.full-login {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.App .products-page .tabs {
  margin-bottom: 0;
}
.App .sales-report .top-button .dropdown-menu {
  left: -30px;
  min-width: 5rem;
}
.App .sales-report .dropdown-menu {
  min-width: 13rem;
}
.modal-icon {
  padding: 28px;
}
.dropdown-item {
  cursor: pointer;
}
.product-integration span {
  display: block;
  padding: 0 5px;
  color: #696464;
  font-size: 10px;
  font-weight: bold;
  margin: 0 2px;
  white-space: nowrap;
}

.product-integration-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 5px;

  & span {
    padding: 0 5px;
    color: #696464;
    font-size: 10px;
    font-weight: bold;
    white-space: nowrap;
  }
}

.channel_block {
  padding: 2px 4px;
  margin-right: 4px;
  white-space: nowrap;
  cursor: pointer;
}
.channal_color_0 {
  background-color: #D8BFD8;
}
.channal_color_1 {
  background-color: #FFA07A;
}
.channal_color_2 {
  background-color: #FFD700;
}
.channal_color_3 {
  background-color: #FFFF00;
}
.channal_color_4 {
  background-color: #90EE90;
}
.channal_color_5 {
  background-color: #DDA0DD;
}
.product-integration-mobile span.channal_color_5 {
  background-color: #DDA0DD;
}
.product-integration span.channal_color_5 {
  background-color: #DDA0DD;

}
.product-integration-mobile span.channal_color_6 {
  background-color: #778899;
  color:#fff;
}

.product-integration span.channal_color_6 {
  background-color: #778899;
  color:#fff;
}
.channal_color_6 {
  background-color: #DDA0DD;
}
.channal_color_7 {
  background-color: #c6a5f5;
}
.channal_color_8 {
  background-color: #eeee90;
}
.channal_color_9 {
  background-color: #d4cbf9;
}

.channal_color_10 {
  background-color: #876fe7;
}

.channal_color_11 {
  background-color: #5f38ff;
  color: #fff;
}
.channal_color_12 {
  background-color: #aeae00;
}
.channal_color_13 {
  background-color: #00A046;
  color:#fff;
}

.mobile .modal-background {
  background-color: rgba(10, 10, 10, 0.96);
}
.max-height150 {
  max-height: 150px;
  overflow-x: auto;
}
.App .navbar-item img {
  max-height: 3.75rem;
}
#iframeID {
  display: none;
}
.shipments-report table.table tr td {
  vertical-align: top;
}
.button_link.smll {
  font-size: 12px;
  height: auto;
}
.button_link.sml {
  height: 1.5em;
  font-size: 10px;
}
.vertical-align-bottom {
  vertical-align: bottom;
}
.sales-report a.dropdown-item {
  padding-right: 1rem;
}
body .without-border {
  border: 0;
}
.App .tabs.is-medium {
  font-size: 1rem;
  margin-bottom: 0;
}

.App .tabs.is-small {
  font-size: 1rem;
  margin-bottom: 0;
}

.dropdown .button.button_link.is-medium {
  color: #4a4a4a;
  size: 1rem;
}
.hide_input {
  opacity: 0;position: absolute;
}
.dropdown .button{
  border: 0;
  color: #485fc7;
}
.pagination .pagination-list {
  text-align: left;
}
.modal-without-scroll .modal-content, .modal-factorychange-status .modal-content,
.modal-factoryadd-delivery .modal-content, .DeliveryData .modal-content,
.modal-factoryadd-category .modal-content, .modal-factorybinding-client .modal-content,
.modal-factorybinding-modal .modal-content, .modal-factoryadd-to-client-tags .modal-content,
.modal-factorymoving .modal-content,
.modal-factorylink-account-terminal .modal-content, .modal-factoryaccrual .modal-content,
.modal-factorymoving-all .modal-content, .modal-factoryproduction-category .modal-content,
.modal-factorymin-balance .modal-content, .modal-factoryoversize .modal-content,
.modal-factoryfiscal .modal-content, .modal-factorywithout-scroll .modal-content {
  overflow: visible !important;
}

.modal-factory .modal-content:has(>.add-expenses) {
  overflow: visible !important;
}

@media (min-width: 768px) {
  .modal-factory .modal-content:has(>.print-modal) {
    overflow: visible !important;
  }
}

body .mySelect__menu-list {
  height: 150px;
}
body .mySelect__menu {
  z-index: 1500 !important;
  height: 150px;
}
.App .products-filter .list-item {
  padding-top: 0px;
}

.has-background-light-warning {
  background-color: hsl(347, 90%, 96%) !important;
}
.has-background-light-black {
  background-color: #ebebeb !important;
}
.has-background-strong-black {
  background-color: #dedede !important;
}

.termo_print input{
  width: auto !important;
  opacity: 1 !important;
  margin-top: 3px;
}
.padding-left-30 {
  padding-left: 30px;
}
.click_element {
  cursor: pointer;
}
.has-text-black {
  color:#4a4a4a ;
}
.has-text-danger {
  color:#f14668 !important;
}
.has-text-warning {
  color: #d4d700;
}
.App .has-text-success {
  color: #48c78e;
}
.has-text-info {
  color: #485fc7;
}
.sale-image img{
  margin-top: 5px;
}
.sale-image {
  text-align: center;
  font-size: 10px;
  width: 70px;
}
/*a.product-name {
    color: #485fc7;
    font-soze: 17px;
}*/
.product-name {
  font-size: 110%;
  font-weight: bold;
  color: #485fc7;
}
.is-hidden {
  display: none;
}
.control.type-sales .radio {
  margin-right: 5px;
}
.control.type-sales {
  text-align: left;
}
.modal-product-report .level-item .heading {
  font-size: 8px;
}
.red_text{
  color:red;
}
.button_loader {
  margin-right: 15px;
  margin-top: 10px;
}

.dashboard-statistics .box .big-number.very-big-number {
  font-size: 1.5rem;
}

.sales-actions {
  margin:0;
}

.sales-actions .column{
  padding: 0;
  margin:0;
}

.main_button {
  width: 32px;
  height: 25px;
  overflow: hidden;
  float: left;
  margin-top: 3px;

  & .main_text {
    display: none;
  }
}

/*.App .image.is-64x64, .App .image.is-64x64 img {
  height: 64px;
  width: auto;
}*/

.is-fullwidth.button {
  height: 50px;
}

.App .progressbar .active a:hover, .underline {
  text-decoration: underline;
}
.App .progressbar {
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.App .progressbar .button{
  margin-top: -2px;
}

.App .progressbar .active a {
  color: #048004;
}

.App .progressbar .active .button.button_link {
  color: #048004;
}
.App .progressbar .button.button_link:hover {
  color:#606161;
  text-decoration: none;
}

.App .progressbar .button.button_link {
  font-size: 0.8rem;
  color: #3273dc;
  text-decoration: none;
  margin-top: -10px;
}

.button_link{
  color: #485fc7;
  text-decoration: none;
}

@keyframes glowing {
  0% { background-color: #054775; box-shadow: 0 0 2px #074673; }
  50% { background-color: #1082d4; box-shadow: 0 0 9px #0e87de; }
  100% { background-color: #074b7b; box-shadow: 0 0 2px #094d7d; }
}

.button_blink {
  animation: glowing 1500ms infinite;
}

.mobile .onbording .buttons {
  justify-content: center;
}

.onboarding .desktop .progressbar a {
  font-size: 14px;
}

.StepProgress b {
  font-size: 80%;
}

.StepProgress {
  position: relative;
  padding-left: 45px;
  width: 50%;
  list-style: none;

  &::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
    border-left: 2px solid #CCC;
  }

  &-item {
    position: relative;
    counter-increment: list;

    &:not(:last-child) {
      padding-bottom: 20px;
    }

    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      left: -30px;
      height: 100%;
      width: 10px;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: -42px;
      width: 26px;
      height: 26px;
      border: 2px solid #CCC;
      border-radius: 50%;
      background-color: #FFF;
    }

    &.is-done {
      &::before {
        border-left: 2px solid green;
      }
      &::after {
        content: "✓";
        font-size: 15px;
        color: #FFF;
        text-align: center;
        border: 2px solid green;
        background-color: green;
      }
    }

    &.current {
      &::before {
        border-left: 2px solid green;
      }

      &::after {
        content: counter(list);
        padding-top: 1px;
        width: 26px;
        height: 26px;
        //top: -4px;
        //left: -40px;
        font-size: 14px;
        text-align: center;
        color: green;
        border: 2px solid green;
        background-color: white;
      }
    }
  }

  strong {
    display: block;
  }
}

.mobile .box.inventory-report, .mobile .box.dashboard, .mobile .box.sales-report {
  border-radius: 0 0 12px 12px;
  border-left: 1px solid #dbdcdc;
}

.box.inventory-report .very-big-number:not(.big-number) {
  font-size: 140%;
}

.is-mobile .is-medium.input {
  height: 90%;
}

.mobile .box.dashboard  {
  background-color: #e6ecf2;
}

.mobile-moving {

  &.columns {
    margin-bottom: 0
  }
  & .moving-item {
    display: flex;
    gap: 5px;
    align-items: center;

    & label {
      margin: 0
    }
  }
}

.mobile .navbar .navbar-menu .navbar-start .navbar-item .level-item.start svg {
  width: 26px;
  height: 26px;
}

.mobile .navbar.is-fixed-top {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  z-index: 32;
}

.mobile .navbar.is-fixed-bottom {
  box-shadow: 5px 0px 5px 3px #d4cfcf;
}

.addUser .checkbox input {
  margin-right: 10px;
}

.react-confirm-alert-body-element .modal-container {
  z-index: 99;
}

.modal-product-report .right-side{
  margin-left:30px;
}

.modal-product-report .level-item{
  justify-content: left;
}


.button-actions-image {
  width: 100%;
  top: 0;
  gap: 10px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.filters .is-medium.input {
  font-size: 1rem;
}
.filters .list .list-item,  .filters .list{
  border: 0;
  box-shadow:none;
}

.help-block .menu-list a {
  color: black;
}
.fa-power-off {
  cursor: pointer;
}
.add-sale-mobile {
  margin-left: 100px;
}
.selected-item-text {
  flex: none !important;
  width: 10%;
  padding-top: 17px !important;
}
.my-facebook-button-class {
  border: 0px;
  background: #1877F2;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 250px;

  &:hover {
    color: white;
  }

  & i {
    padding: 2px 2px 0 7px;
    background: #FFFFFF;
    color: #1877F2;
    border-radius: 2px;
    font-size: 1.5rem;

    &::before {
      position: relative;
      bottom: -3px;
    }
  }

  &.iconClass {
    min-width: 0
  }
}
.align-left {
  float:left;
  margin-right: 4px;
  overflow: hidden;
}

.App .title.header-country {
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
}

.App a.header-logo {
  text-decoration: none;
}


.dropdown .button {
  padding-left: 0em;
  padding-right: 0em;
}

.dropdown.import-products .dropdown-trigger .button,
.dropdown.export-products .dropdown-trigger .button {
  background-color: hsl(207, 61%, 53%);
  color: #fff;
  fill: #fff;
  stroke: #fff;
  font-size: 16px;
  border-radius: 16px;
  padding: calc(1em + 0.25em);
  &:focus {
    border: none
  }
}
.dropdown.import-products .dropdown-trigger .button .icon, .dropdown.export-products .dropdown-trigger .button .icon {
  display: none;
}

.dropdown.import-products .dropdown-trigger .button .toggle-icon .svg-inline--fa, .dropdown.export-products .dropdown-trigger .button .toggle-icon .svg-inline--fa {
  width: 12px;
  height: 12px;
}

.dropdown.export-products .dropdown-menu .dropdown-content .dropdown-item .svg-inline--fa{
  stroke: #070600;
}

.dropdown.export-products .dropdown-menu {
  left: -28px;
  z-index: 21;
}

.dropdown.import-products .dropdown-menu {
  left: -48px;
  z-index: 21;
}

.dropdown .button .icon:last-child:not(:first-child) {
  margin-left: -5px;
}
.App .product-name .image.is-64x64 {
  margin-top: 15px;
}
.App .image.is-64x64 {
  overflow: hidden;
  margin-bottom: 12px;
}
.image {
  overflow: hidden;
}

.App .our-service .media  {
  min-height: 90px;
}
.mobile .navbar-brand .button.is-small {
  font-weight: bold;
}
.navbar-brand {
  font-weight: bold;
}


.home-nav-bar .navbar-burger {
  height: 4.25rem;
  width: 4.25rem;
}

.App .our-service .title {
  text-align: left;
}
.App .our-service .title.is-1 {
  text-align: center;
}
.App .title {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.catalog-list {
  vertical-align: top;
}

.catalog-list .column .content .title {
  margin-bottom: 8px;
}

#account-top-bar {
  padding: 10px;
  border-radius: 0px;
}
.ios-app .modal-close {
  margin-top: 30px;
}
.mobile .is-large.modal-close {
  margin-top: -15px;
}

.is-large.modal-close {
  z-index: 5;
}
.ios-app .onboarding #right-side.mobile > section.onbording {
  margin-top: 90px;
}

.onboarding #right-side.mobile > section.onbording {
  margin-top: 47px;
  margin-bottom: 10px;
}
.onboarding #right-side.mobile > section {
  margin-top: 0px;
}
.ios-app.mobile .is-fixed-top .navbar-brand {
  padding-top: 45px;
}

.ios-app #right-side.mobile + section {
  margin-top: 85px;
}
.ios-app .onboarding #right-side.mobile > section {
  margin-top: 0px;
}

.ios-app #right-side.mobile > section {
  margin-top: 90px;
}
#right-side.mobile > section {
  margin-top: 50px;
  margin-bottom: 70px;
}


#right-side > .section  {
  padding-top: 1rem;
}

#right-side {
  padding-left: 0;

  @media (max-width: 900px) {
    padding-right: 0;
    padding-top: 0;

  }
}

.App .margin-bottom0 {
  margin-bottom: 0 !important;
}
.App .margin-left-0 {
  margin-left: 0;
}
.App .margin-bottom5 {
  margin-bottom: 5px;
}

.mobile .modal-icon {
  width: 50%;
}


.mobile .content h1.mobile-title-pr {
  margin-top: 0;
}
.left {
  text-align: left !important;
}
.padding-column .column{
  padding: 0.25rem;
}
.margin-bottom-0 {
  margin-bottom: 3px !important;
}
.App .padding-bottom-10 {
  padding-bottom: 10px !important;
}


.icon-add-category {
  padding-top: 12px;
}
.select, select {
  width: 100%;
}
.category-filter .field {
  width: 100%;
}
.radio input {
  margin: 5px;
}
/*.radio input {
  border: 0px;
    width: 100%;
    height: 2em;
}*/
.ex-open-modal-wrapper {
  text-align: right;
  margin: 10px 0
}

.big-number {
  font-size: 25px;
  font-weight: 600;
}
.box .text-danger {
  color: #f14668;
}
.box .text-success {
  color: #46c37b;
}
.box .text-warning {
  color: #e5e844;
}
.inventory-report table.table tr td .box, .inventory-report table.table thead tr th {
  text-align: center;
}
table.table tr td, table.table thead tr th

{
  text-align: left;
  vertical-align: middle;
}
table.table td:first-child, table.table th:first-child
{
  text-align: left;
}

.table-input-center {
  text-align: center;
  display: flex;
  justify-content: center;
}
.nowrap {
  white-space: nowrap;
}
.mobile .shipments-order-list .list-item .column {
  padding: 0.25rem;
}
.text-align-center {
  text-align: center;
}
.text-align-justify {
  text-align: justify;
}

.table-input-center input {
  width: 50%;
}
.alignright {
  text-align: right;
}
.mobile .big-text {
  font-size: 125%;
}
.big-text {
  font-size: 200%;
  font-weight: bold;
}
.middle-text {
  font-size: 100%;
  font-weight: bold;
  margin-left: 3px;
}
.bold-text {
  font-weight: bold;
}
.field-30 {
  width: 20%;
  text-align: left;
}
.sales-order-list {
  font-size: 80%;
}
.bold {
  font-weight: bold;
}
.small-text {
  font-size: 60%;
}
.w70 {
  width: 70%;
}
.w30 {
  width: 20%;
}
.sale-total {
  margin-top:10px;
  margin-bottom:10px !important;
}

.searchProduct {
  margin-bottom: 10px;
}
.margin-right-50 {
  margin-right: 50px;
}
.searchProduct .field{
  margin-bottom: 0;
}
.font-size-80, .font-size-80 .checkbox {
  font-size:90%;
}
.font-size-120 {
  font-size:120%;
}
.font-size-110 {
  font-size:110%;
}
.font-size-80 .big-number {
  font-size: 25px;
  font-weight: 600;
  white-space: nowrap;
}
.nowrap {
  white-space: nowrap;
}
.inventory-report .table-input-center input {
  width: 100px
}
.modal .control.has-icons-left .icon, .modal .control.has-icons-right .icon {
  z-index: 0;
}
.control.has-icons-right .input.for-small-icon {
  padding-right: 1.25em;
  font-size: 80%;
  height: 38px;
}
.control.has-icons-right .input.for-slim-icon {
  padding-right: 1.25em;
  font-size: 80%;
  height: 20px;
}
.control.has-icons-right .icon.for-slim-icon  {
  height: 1.5em;
}
.products-filter .search-field .control {
  width: 100%;
}
.price-block .mySelect__value-container {
  height: 42px;
}
.mySelect__value-container {
  height: 38px;
}
.control .input.is-small {
  height: 2.5em;
}
.mobile .control .input {
  height: 38px;
}

.control .input {
  height: 40px;
}
.subtitle.your-money {
  font-weight: bold;
  font-size: 200%;
}

.dashboard-statistics .box .big-number{
  font-size:1.5rem;
}
.dashboard-block-sales {
  margin-top: 10px;
}
.fullwidth {
  width: 100%;
}

.info-bar-mobile {
  padding: 4px;
}
.dashboard-block-sales .box {
  height: 100%;
}

.start-block .todo-cmp__list li label input + span::before,
.setting-page .todo-cmp__list li label input + span::before,
.start-block .todo-cmp__list li label input + span::after,
.setting-page .todo-cmp__list li label input + span::after {
  border: 1px solid black;
  border-radius: 13px;
  height: 28px;
  width: 28px;
  text-align: center;
  left: -38px;
  top: 5px;
}
.setting-page .todo-cmp__list li label input:checked + span {
  color:#4a4a4a;
}
.level-item.go-out {
  justify-content: right;
}

.big_link {
  font-family: 'Mariupol';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #00BBF9;
  text-decoration: none;
}
.setting-page .box{
  border-radius: 0px;
}

.setting-page .box.partners-tab .box {
  border-radius: 16px;
}

.setting-page .integrations-page .title{
  text-align: left;
}
.setting-page .integrations-page .box{
  border-radius: 16px;
}
.setting-page .tabs{
  margin-bottom: 0px;
}
.tarif-plan .level-item {
  text-align: center;
}
.tarif-plan .level-item > .box{
  width: 70%;
}
.tarif-plan {
  text-align: center;
}
.has-background-dark {
  background: hsl(0, 0%, 21%);
}
.has-background-white {
  background: #fff;
}

.has-background-warning {
  background: #f03a5f;
}
.has-background-success {
  background: #48c78e;
}

.has-background-info-dark a{
  color: #fff;
}
.has-background-info-dark {
  background-color: #1d72aa!important;
  color: #fff;
}

.good-money {
  font-size: 2rem;
  font-weight: 700;
  color: #393a3d;
  line-height: 1em;
  margin-bottom: 4px;
}

.mobile .dashboard .notification {
  padding-right: 1.5rem;
}

.navbar-item img {
  max-height: 2.75rem;
}

.navbar-item {
  justify-content: center;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
}

.text-profit{
  text-transform: uppercase;
}

.mobile .middle-money {
  font-size: 1rem;
}

.middle-money {
  font-size: 1.3rem;
  font-weight: 700;
  color: #393a3d;
  line-height: 1em;
  margin-bottom: 4px;
}
.dashboard-statistics .title.is-6 {
  margin-bottom: 10px;
}
.dashboard-statistics .dashboard-block-sales .box {
  padding: 12px;
}
.dashboard .card{
  width: 100%;
}
.orange-color {
  color: #deb100;
}
.filters {
  margin-bottom: 15px;
}
.filters-accounts {
  width: 200px;
}
.filters-date .field.has-addons {
  justify-content: right;
}

.filters-date .field{
  margin-bottom:3px;
}
.filters-date-string {
  font-size: 9px;
  margin: 0px;
  padding: 0px;
  text-align: right;
}
.filters-date-string span, .like-link {
  cursor: pointer;
  color:#3e8ed0;
}
.react-confirm-alert-button-group button:last-child {
  background-color: #0ed238;
}
.react-confirm-alert-body h1 {
  font-weight: bold;
  font-size: 130%;
}
.App .mySelectProduct__menu {
  z-index: 5;
  text-align: left;
}

.select-products-menu.fixed-copy-select__menu {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  z-index: 5;
}

.mySelectProduct__menu .field .checkbox input {
  float:left;
}
.field .checkbox input {
  margin-right: 5px;
}
.App .margin-10, .margin-10  {
  padding: 0.75rem;
}

.margin-5  {
  padding: 5px;
}
.marginn-5  {
  margin: 5px;
}
.App .margin-top-5, .margin-top-5  {
  margin-top: 5px;
}
.App .margin-left-5, .margin-left-5  {
  margin-left: 5px;
}

.App .margin-right-5, .margin-right-5 {
  margin-right: 5px;
}

.App .margin-top-10, .margin-top-10 {
  margin-top: 10px !important;
}
.App .margin-top-60, .margin-top-60  {
  margin-top: 60px;
}
.App .margin-top-20, .margin-top-20 {
  margin-top: 20px;
}
.App .margin-top-40, .margin-top-40 {
  margin-top: 40px;
}
.App .padding-vert0, .padding-vert0 {
  padding-bottom: 0;
  padding-top: 0;
}

.App .margin-side-10, .margin-side-10 {
  margin: 0 10px;
}

.App .margin-bottom-10, .margin-bottom-10 {
  margin-bottom: 10px !important;
}

.App .margin-bottom-5, .margin-bottom-5  {
  margin-bottom: 5px !important;
}

.App .margin-bottom-15, .margin-bottom-15 {
  margin-bottom: 15px;
}
.App .margin-bottom-40, .margin-bottom-40  {
  margin-bottom: 40px;
}

.margin-left-30 {
  margin-left: 30px;
}

.mobile .shipments-report.box {
  padding: 1rem;
}
.list-sales-order .column, .list-sales-order {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.mobile .navbar-brand .buttons:last-child {
  margin-bottom: 0;
}

.mobile .table td {
  padding: 0;
}
.mobile .box {
  padding: .75rem;
}
.mobile .section {
  /*padding: 1rem 1rem;*/
  margin-left: 6px;
  width: 100%;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}
.inventory-report .button.button_link {
  padding-left:5px;
  padding-right:5px;
}
.mobile .inventory-report .button.button_link {
  height: 0;
  margin-top: 9px;
}
.price-block .delete-on-sale {
  margin-top: 35px;
}

.width30px {
  width: 30px;
  flex: none;
}
.delete-on-sale {
  flex: none;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-on-bundle {
  flex: none;
  margin-top: 4px;
}
.small-number-box .big-number{
  line-height: 20px;
  font-size: 20px;
}
.med-number-box .big-number{
  line-height: 30px;
  font-size: 23px;
}
.small-number-box {
  text-align: center;
  padding: 10px;
  margin-bottom:9px !important;
}
.mobile .product-mobile-block{
  margin-bottom: 0px;
}
.mobile .card-content{
  padding: 0.5rem;
}
.display-flex {
  display: flex;
}

.display-flex-column-gap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
}
.info-bar {
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.like-select .icon{
  height: 1.9em;
}
.like-select,  .like-select input{
  /*margin-left: 10px;
width: 100px;*/
  height: 39px;
}
.home-landing {
  background-color: aliceblue;
}
.home-landing .section, .shop-landing .section {
  padding: 0;
}
.mobile .hero-body {
  padding-bottom: 0;
}
.mobile .hero-question .hero-body{
  padding-left:0;
  padding-right:15px;
}
.mobile .todo-cmp {
  padding-left:0;
  padding-right:0;
}
.mobile .home-question-block {
  margin:10px 0px;
  padding-left:5px;
  padding-right:5px;
}
.home-question-block {
  /*background-image: linear-gradient(141deg, #f4fbff 0%, #e3ecf5 71%, white 100%);*/
  /*margin:70px;*/
}
.padding-top-bottom {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ex-open-modal-wrapper .button.is-medium {
  font-size: 1.05rem;
  margin-right: 2px;
}

%boxshadow {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0,0,0,0.05), 0 0.5rem 1.125rem rgba(75,0,0,0.05);
}
ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 0rem;
  font-size:120%;
  > li {
    background: white;
    border-radius: 16px;
    @extend %boxshadow;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 15px 15px 15px 3rem;
    position: relative;


    + li {
      margin-top: 2rem;
    }
  }

  @media (max-width: 768px) {
    font-size: 100%;
  }
}


.our-service h2{
  font-weight: normal;
}
.mobile .our-service {
  width: 100%;
}
.our-service {
  width: 80%;
  margin:0 auto;
}
.mobile .review-block {
  padding: 0px;
}
.mobile .hero-body#review {
  padding: 0;
}
.review-block {
  padding: 5px;
  text-align: left;
  background-color: #e9eefd;
}
.home-social-network{
  width: 200px;
  margin: 0 auto;
}
.mobile .tarif-plan .box:first-child {
  width: 70%;
}
.free-tarif-block .hero-body{
  padding-top: 100px;
  padding-bottom: 100px;
}

.our-service .subtitle {
  text-align: left;
}

.mobile  .free-tarif-block .title{
  font-size: 3rem;
}
.free-tarif-block {
  background-color: #c3e6ff;
}
.mobile .visa-footer img {
  width: 150px;
}
.feature-block img{
  width: 200px;
}
.mobile .feature-block .tile.is-child .content{
  margin-bottom: 30px;
}

.tile.is-child {
  padding: 10px;
}

.mobile .feature-block {
  padding: 0px;
}

.feature-block .title:not(.is-spaced) + .subtitle {
  margin-top: -0.25rem;
}

.feature-block .content{
  padding: 15px;

}
.feature-block {
  padding: 50px;
}

.home-landing .tarif-plan .level-item,
.shop-landing .tarif-plan .level-item {
  flex-grow: 0;
}

.hero.is-info.tarif-plan svg {
  color: #3273dc;
}
.hero.is-info.tarif-plan .level .title, .tarif-plan .level .title {
  color:black;
  margin-bottom: 12px;
}
.tarif-plan .panel-heading {
  font-weight: bold;
}
.small-font {
  font-size:0.75rem;
}
.soon {
  font-size:0.75rem;
  color:red;
  vertical-align:text-top;
}
.todo-cmp {


  &__list {
    list-style: none;
    padding: 0;

    li {
      padding: 10px 0 15px;
      margin: 0;
      text-align: left;
      width: 100%;

      label {
        cursor: pointer;
        width: 100%;
        display: block;


        input {
          float: left;
          opacity: 0;
          width: 0;
        }

        span {
          position: relative;
          display:block;
          transition: all 200ms ease-in-out;
        }


        input + span::after {
          content: "";
          display: block;
          border: 1px solid #497081;
          border-radius: 13px;
          height: 20px;
          width: 20px;
          position: absolute;
          left: -26px;
          top: -1px;
          z-index: 10;
          margin-left: 5px;

          transition: all 550ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
          transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
          /* https://matthewlein.com/tools/ceaser */
        }

        input + span::before {
          content: "\2714";
          display: block;
          font-size: 22px;
          height: 20px;
          width: 20px;
          position: absolute;
          left: -26px;
          top: -1px;
          z-index: 10;
          opacity: 0;
          margin-left: 5px;
        }

        input:checked + span {
          color: #23d15f;
          transition: all 200ms ease-in-out;
        }

        input:checked + span::after {
          opacity: 0;
          height: 17px;
          width: 17px;
          top: -2px;
          right: -2px;
        }

        input:checked + span::before {
          opacity: 1;
          transition: all 550ms ease-in-out;
        }
      }
    }
  }
}
.widgetExpensesCategory {
  margin-right: .75rem;
  margin-left: .75rem;
  margin-bottom: .75rem;
}
.widgetExpensesCategory .column {
  padding: 0;
}
.discount_type_select {
  width: 40px;
  height: 30px;
}
.discount-field {
  height: 38px;
}
.sale_modal .has-icons-right .discount_type_icon {
  top:-4px;
}
.user-info {
  color:#fff;
  font-size: 80%;
}
.sale_modal .tabs {
  margin-bottom: 0px;
}

.sale_modal .box {
  border-radius: 0 0 12px 12px;
}
.text-underline {
  text-decoration: underline;
}
.pr-item .control {
  font-size: 0.85rem;
}
.surrender {
  font-size: 80%;
  text-decoration: underline;
  color:#485fc7;
  cursor: pointer;
}
.field-control .image{
  object-fit: cover;
  flex-shrink: 0;
  flex-grow: 0;
}
.field-control {
  display: flex;
  align-items: center; /* Выравнивание элементов по центру по вертикали */
  gap: 5px; /* Расстояние между элементами внутри field-control */
}

.hover {

  &:hover {
    background-color: #f2efef;
  }

  &:hover .column-name-wrapper .column-name {
    background-color: #f2efef;
  }

  & .column-name {
    height: 100%;
    background: white;
    padding-left: 0.75rem;
  }
}
.mobile .prepaid-balance {
  margin-top: 0px;
}

.prepaid-balance {
  font-size: 100%;
  margin-top: 10px;
  font-weight: bold;
}


.sales-page .tabs, .reports-page .tabs{
  margin-bottom: 0px;
}
.bottom-active, a.bottom-active:hover {
  color:red;
}
.my-vcenter {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-button {
  margin-bottom: 5px;
}
.icon-button {
  border: 0;
}
.minWith130 {
  min-width: 130px;
}
.video-help iframe{
  height: 400px;
}
.mobile .sales-report td {
  padding-left: 0;
}
.mobile .sales-report {
  overflow: hidden;
  padding: 15px;
}
.subtitle {
  font-size: 80%;
}
.mobile .table td, .mobile .table th {
  border-color: #9e8686;
}
.orders-report .tabs {
  margin-bottom: 0;
}
.sales-report .level {
  margin-bottom: 0;
}
.video-help .list-item, .cursor-pointer {
  cursor: pointer;
}
.help-image, .width80 {
  width: 80%;
}
body .App .padding-bottom0, body .modal .padding-bottom0 {
  padding-bottom: 0px !important;
}
.App .small-padding .column {
  padding: 0.3rem;
}
.App .button.button_link:hover, .modal-container .button.button_link:hover {
  background: transparent;
  color: #485fc7;
  text-decoration: underline;
}
.App .button.button_link, .modal-container .button.button_link{
  border-radius: 0;
  border:0;
  padding: 0;
  background: transparent;
  color: #485fc7;
  white-space: normal;
}
.box .total-subtitle {
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 17px;
}
.gapless-5.is-gapless .column {
  margin: 0 5px;
}
.width49 {
  width: 47%;
}
.box.short-filter div {
  vertical-align: top;
}
.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #9a9494 !important;
}
.padding-left-right5 {
  padding-left: 5px;
  padding-right: 5px;
}
.padding-left90 {
  padding-left: 80px !important;
}

.important {
  font-size: 130%;
  font-weight: bold;
  color: #00c4a7;
}
.opacity05 {
  opacity: 0.5
}
.opacity0 {
  opacity: 0
}
.columns.is-mobile > .column.is-one-six {
  width: 15%;
}

.telegram-side {
  margin-top: 50px;
}

.telegram-side figure{
  float:left;
}
.uppercase {
  text-transform: uppercase;
}
.shop-landing .bullet .panel-block.is-active .panel-icon,
.home-landing .last-block  .bullet .panel-block.is-active .panel-icon  {
  color:black;
}
.last-block .bullet .panel-block.is-active {
  color: black;
}
.bullet .panel-block.is-active .panel-icon {
  color:white;
  font-size: 25px;
}
.shop-landing .bullet .panel-block.is-active, .home-landing .last-block .bullet .panel-block.is-active{
  color:black;
}
.bullet .panel-block.is-active {
  border:0;
  text-align: left;
  font-size: large;
  color: white;
  font-weight: bold;
}

.mobile .online-shop .free-tarif-block  {
  margin-top: 20px;
}
.very_big_amount {
  font-size: 60px;
  text-align: center;
  line-height: 1;
  font-weight: bold;

  @media (max-width: 400px) {
    font-size: 46px;
  }
}
.our-clients .title.is-1{
  font-size: 3rem;
}
.our-clients .title.is-4{
  font-weight: normal;
}
.mobile .online-shop .free-tarif-block  .hero-body {
  padding-top: 50px;
  padding-bottom: 50px;
}

.online-shop #review {
  margin-top: 20px;
}
#review .padding-top-bottom {
  padding-left: 10px;
}
.search-filter .select-products-1 .control {
  font-size:80%;
}
.product_main .big-number {
  display: inline;
  white-space: nowrap;
}
.coins {
  color:#585858;
  font-size: 80%;
}

.welcome-block .tile, .welcome-block.tile {
  border-radius: 25px;
}
.welcome-block .button-block button{
  margin-right: 5px;
}
.welcome-block .button-block{
  margin-top: 10px;
}

.welcome-block li{
  margin-top: 20px;
}
.soon {
  color: #FFFFFF;
  height: 20px;
  display: flex;
  padding: 2px 8px;
  font-size: 12px;
  background: #0651FF;
  align-items: center;
  font-weight: bold;
  line-height: 16px;
  border-radius: 8px;
  justify-content: center;
  display: inline;
}


.mobile.progressbar li:after {
  height: 0;
}

.mobile.progressbar li {
  width: 100%;
  display: block;
}

.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 16%;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height : 30px;
  border: 1px solid #ddd;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
  z-index : 2;
  position: inherit;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index : 1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: green;
}
.progressbar li.active:before {
  border-color: green;
}
.progressbar li.active + li:after {
  background-color: green;
}


.radio-with-info-wrapper {
  width: fit-content;
  position: relative;
  display: inline-flex;
  user-select: none;
}

.info-icon {
  position: absolute;
  top: -2px;
  right: -13px;
  cursor: pointer;
}

.info-box {
  width: 100%;
  min-width: 250px;
  max-width: 400px;
  max-height: 70px;
  position: absolute;
  background: gray;
  padding: 5px;
  border-radius: 5px;
  font-family: inherit;
  font-size: 0.8em;
  color: #fff;
  z-index: 25;
  word-wrap: break-word;
  overflow: auto;
}

.select-wrapper-load {
  padding:  30px;
  position: relative;
}

.write-off-mobile-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  & div.level-item{
    margin-bottom: 0
  }
}

.link-icon-centred, .align-center {
  display: flex;
  align-items: center;
}

.display-flex-row-gap {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  word-break: break-all; /* Для переноса слов на новую строку */
  overflow-wrap: break-word;
}
.display-flex-row {
  display: flex;
  gap: 5px;
  align-items: center
}
.display-flex-center {
  display: flex;
  height: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.radio.display-flex-radio {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.display-inline {
  display: inline;
}

.min-width-200 {
  min-width: 200px;
}

.position-relative {
  position: relative;
}

.checkbox-absolute {
  position: absolute;
  top: 4px;
  left: 4px;
}

.icon-rotate-90-right {
  transition: 1s;
  transform: rotate(90deg);
}

.icon-rotate-90-left {
  transition: 1s;
  transform: rotate(-90deg);
}

.hiding-arrow-wrapper {
  display: flex;
  position: absolute;
  transition: 1s;
  bottom: -24px;
  left: 50%;
  transform: translate(-50%);
  background: #fff;
  border-radius: 0 0 10px 10px;
}

.mass-button-addition {
  padding: 0 10px;
  transition: 1s;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.select-column {
  background: #fafafa;
  position: sticky;
  top: 0;
  z-index: 20;
}

.icon-wrapper {
  width: fit-content;
  display: inline-flex;
  flex-direction: column;
  margin-left: 3px;
  color: #485fc7;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    @media (min-width: 768px) {
      color: black;
    }
  }

  & .arrow-up {
    margin-bottom: -6px;
  }

  & .active {
    color: red;
  }
}

.list-bg-fa {
  background: #fafafa;
}



.list.sort-list {
  width: 100%;
  min-width: 200px;
  position: absolute;
  z-index: 30;
  top: 31px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);

  & .list-item:not(:last-child) {
    border-bottom: 1px solid #dbdbdb;
  }
  & .list-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  & .icon-wrapper {
    margin-top: 5px;
    margin-bottom: -5px;
  }
}

.radio.setting-additional-checkbox {
  display: flex;
  align-items: center;
  user-select: none;
}

.button-block.shipments-excel-btn {
  text-align: right !important;
}

.tag-wrapper {
  height: fit-content;
  margin: 2px 0;
  padding: 2px 5px;
  white-space: nowrap;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}

.tag-group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  &.tag-grow {
    & span {
      flex: 1;
    }
  }
}

.is-mobile.level.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;

  & .level-item {
    flex-grow: 0;
    margin: 0
  }
  & .level-item:not(:last-child) {
    flex-grow: 0;
    margin: 0
  }
  & .level-item:not(.is-narrow) {
    flex-grow: 0;
  }

}

.button.button-link-icon {
  height: fit-content;
  width: fit-content;
  padding: 0;
  margin-top: 2px;
  background: transparent;
  border: 0;
  color: #485fc7;
  display: block;

  &:hover {
    color: black;
  }

  &:focus:not(:active) {
    box-shadow: none;
  }

  &:disabled {
    background: transparent;
  }

  &.white {
    color: #fff;

    &:hover {
      color: #5e96ff;
    }
  }
}



.integration-tag {
  margin: 0;
  padding: 5px 10px;
  color: #434343;
  background: #ececec;

  &.active {
    color: #46c37b;
    background: #c3ffc3;
  }
  &.inactive {
    color: #f14668;
    background: #ffdbe2;
  }
}

.integration-wrapper {
  padding: 1rem
}

.box.filter {
  border-radius: 0 0 16px 16px;
  @media (max-width: 768px) {
    box-shadow: none;
    background: transparent;
    margin: 0!important;
  }
}

.add-group-style.buttons:not(:last-child) {
  margin-bottom: -0.5rem;
}

.App .section .box.filter {
  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 5px;
  }
}

.base-filter-layout {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;

  & .filter-box .control .input, .button {
    height: 30px;
    font-size: 1rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  & .filter-box .control span.icon.is-left  {
    font-size: 13px;
  }

  & .control .input.small-input {
    width: 100px;
  }

  & .dropdown-menu {
    right: 0;
    left: auto;
  }

  @media (max-width: 768px) {
    box-shadow: none;
    background: transparent;
    gap: 10px;
  }
}

.date-range-wrapper {
  & .field.has-addons {
    margin: 0;
  }
  & .filters-date-string {
    text-align: left;
  }

  & .control input {
    height: 30px;
  }

  @media (max-width: 768px) {
    width: 100%;

    & .control {
      width: 50%;
    }
  }
}

.filter-box {
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;

  &.nowrap {
    flex-wrap: nowrap;
  }

  &:first-child {
    flex-grow: 0;
  }

  &:nth-child(2) {
    flex-grow: 1;
    @media (max-width: 768px) {
      order: 3;
      width: 100%;
    };
  }

  &:last-child {
    flex-grow: 0;
  }

  & .checkbox {
    margin: auto 0;

    & input {
      margin-top: 5px
    }
  }

  @media (max-width: 768px) {
    row-gap: 5px
  }
}

.fixed-filter {
  width: 320px;
  height: 100vh;
  overflow: auto;
  position: fixed;
  background: #fafafa;
  transition: 0.5s;
  right: -320px;
  top: 0;
  transform: translateX(0);
  z-index: 33;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  &.shadow-box {
    box-shadow: -29px -3px 37px -22px rgba(0,0,0,0.26);
  }

  & .filter-header {
    height: fit-content;
    display: flex;
    gap: 10px;
    align-items: center;
    user-select: none;
    cursor: pointer;
    flex-grow: 0;
  }
  & hr {
    margin: 0 0 1.5rem 0;
    min-height: 2px;
  }

  .wrapper-hidden-filter {
    flex-grow: 1;
  }

  .filter-button-close {
    position: sticky;
    bottom: -1rem;
    padding: 1rem;
    background: #fafafa;
    flex-grow: 0;
  }

  & svg {
    transform: rotate(180deg);
  }
}

.price-from-to-filter {
  display: flex;
  gap: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.integrations-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  align-items: flex-start;

  @media(max-width: 1050px) {
    grid-template-columns: 100%;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}

.add-group-style.buttons:not(:last-child) {
  margin-bottom: -0.5rem;
}

.discounts-label {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.discounts-switch {
  margin-right: 10px;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.discounts-switch .discounts-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.discounts-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.discounts-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.discounts-input:checked + .discounts-slider {
  background-color:#3e8fd0;
}

.discounts-input:checked + .discounts-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.discounts-slider.round {
  border-radius: 34px;
}

.discounts-slider.round:before {
  border-radius: 50%;
}

.discounts-delete-btn {
  display: block;
  margin-bottom: 15px;
  margin-top: -15px;
  text-align: right;
}

.react-datepicker-popper {
  position: relative;
  z-index: 3;
}

.columns .column .content.permissions-list-title {
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.columns .column .permissions-line {
  background-color: #cccccc;
  margin-top: 5px;
  margin-bottom: 5px;
}

.App .partners-tab .column.is-one-third {
  display: inline-block;
  flex: none;
  width: 33.3333%;
}

.partners-tab .partners-link-thumb {
  margin: 0 auto 30px;
  position: relative;
  width: 350px;
}

.partners-tab .partners-link-input {
  padding: calc(0.5em - 1px) 1em;
  height: 3em;
  width: 100%;
  font-size: 1rem;
  border-color: #dbdbdb;
  border-width: 1px;
  border-radius: 12px;
  border-style: solid;
  outline: none;
}

.partners-tab .partners-link-btn {
  position: absolute;
  right: 3px;
  top: 3px;
  border: none;
  outline: none;
  background-color: #fff;
  color: #485dc7;
}

.order-row {
  cursor: pointer;
  position: relative;

  & .dot {
    & .icon.is-small {
      display: none;
    }
  }
  & .dropdown-trigger {
    margin-top: 2px;
    margin-left: 5px;
    & .button {
      background: transparent;
    }
  }

  & .dropdown-item {
    padding: 0;
    & span {
      display: block;
      padding: 0.375rem 1rem;
    }
  }

  & .dropdown svg, use {
    color: inherit;
  }
  & .svg-actions {
    font-size: 1.5rem;
    cursor: pointer;
  }
}
.order-img {
  height: 32px;
}




.icon-open {
  color: #c7c7c7;
  cursor: pointer;
  transform: rotate(90deg);

  &.active {
    color: #000000;
    transform: rotate(-90deg);
  }
}

.button.button-link-without-style, .button-link-without-style {
  text-decoration: none;
  padding: 0;
  margin: 0;
  text-align: left;
  background: transparent;
  border: none;
  white-space: normal;
  height: fit-content;
  color: #485fc7;

  &:focus:not(:active) {
    box-shadow: none;
  }
  &:hover {
    color: #363636;
  }
  @media (max-width: 768px) {
    -webkit-tap-highlight-color: transparent;
  }
}

.button.is-info-light {
  &.is-info, & {
    color: #008eff;
    background: #008eff26;
    border: none;

    &:hover {
      color: #008eff;
      background: #008eff40;
    }
  }

}

.dropdown.disabled-background {
  & .button {
    background: transparent;
  }
}

.button.button-icon {
  text-decoration: none;
  padding: 0;
  margin: 0;
  text-align: left;
  background: transparent;
  border: none;
  white-space: normal;
  height: fit-content;

  &:focus:not(:active) {
    box-shadow: none;
  }
  @media (max-width: 768px) {
    -webkit-tap-highlight-color: transparent;
  }

  &.is-danger {
    color: #f14668;
    background: transparent;

    &:hover, &:focus, &:active {
      background: transparent;
      color: #ff6e8b;
    }

    &[disabled] {
      color: #f14668;
      border-color: #f14668;
      background: transparent;
      box-shadow: none;
    }
  }
  &.is-success {
    color: #46c37b;
    background: transparent;

    &:hover, &:focus, &:active {
      background: transparent;
      color: #0ed238;
    }
  }
}

.commission-label {
  margin-top: -10px;
  margin-bottom: 5px;
  font-size: 0.8rem;
}

.dropdown-custom-price {
  margin-top: -5px;
  margin-bottom: -6px;

  & .button {
    font-size: 0.8rem;
    background: transparent;
  }
  & .icon:not(.show-icon) {
    display: none;
  }

  & svg {
    margin-bottom: -5px;
    cursor: pointer;
    transform: rotate(90deg);
  }

  & .dropdown-content {
    width: max-content;
    min-width: 150px;
    max-width: 300px;
  }

  & .dropdown-custom-price-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 200px;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  & .active-discount.dropdown-item  {
    background: rgba(14, 210, 56, 0.2);
  }

  & .dropdown-discount-input {
    //width: 65px;
    flex: 1;
  }

  &.position-center .dropdown-menu{
    right: 50%;
    transform: translate(-50%);
  }

}

.commission-wrapper {
  & .commission-block {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  & .text-block {
    font-size: 1.3rem;
  }

  & .control {
    width: fit-content;
  }

  & .label {
    margin-bottom: 0 !important;
  }
}

.icon-action {
  font-size: 0.9rem;
  cursor: pointer;
  &.close {
    color: red;
  }
  &.confirm {
    color: green;
  }

  &.link {
    color: #485fc7;
  }
}

.top-mobile-button-group {
  & button.button {
    margin-bottom: 0;
  }
  & a.button {
    margin-bottom: 0;
  }
}

.reports-page {

  @media (max-width: 768px) {
    & .statisctic {
      margin-top: 10px !important;
    }
  }
}

.report-list {
  overflow-x: auto;

  & .list:not(.sort-list) {
    min-width: 1500px;
  }

  & .column-name-wrapper {
    padding-left: 0;
    position: sticky;
    left: -11px;

    & .column-name {
      height: 100%;
      background: white;
      padding-left: 0.75rem;
    }
  }
}

.report-general {
  overflow-x: auto;
}

.dropdown.dropdown-custom {
  & .button {
    height: auto;
    font-size: 15px;
    background: transparent;
    color: #000;
    padding: 4px 12px;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid #dbdbdb;

    &:focus:not(:active) {
      border: 1px solid transparent;
    }
    &:hover {
      border-color: #b5b5b5;
    }
  }
  & .icon:not(.show-icon) {
    margin-left: 0 !important;
  }
  & .dropdown-item {
    padding: 0;
    margin: 0;
  }

  & .customize-item {
    padding: 6px 16px;
  }
}

.dropdown.dropdown-item-without-padding {
  & .dropdown-item {
    padding: 0;
  }
}

.modal-factorycustomize-columns {

  & .modal-content {
    width: 85%;
    @media (max-width: 768px) {
      width: 100%;
    }

    & .pick-column {
      font-size: 18px;

      & .customize-pick-list {
        max-height: 300px;
        overflow: auto;
        background: #efefef;
        padding: 10px 0;

        & .pick-item {
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 5px 10px;
          cursor: pointer;
          user-select: none;

          &:hover {
            background: #dfdfdf;
          }
        }
      }
    }

    & .selected-column {
      font-size: 18px;


      & .selected {
        font-size: 1rem;
      }

      & .customize-selected-list {
        display: flex;
        flex-direction: column;
        max-height: 300px;
        overflow: auto;

        & .list-item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & svg {
            height: fit-content;
          }
        }
      }
    }
  }
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;

  & .nav-item {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.dropdown.disabled-default {

  & button.button:first-child {
    @media (max-width: 768px) {
      background: transparent;
      color: currentColor;
    }
  }

  & .button span {
    height: 100%;
  }

  & .icon.is-small {
    display: none;
  }
  & .dropdown-item {
    padding: 0;

    & * svg, use {
      color: currentColor;
    }
  }
}
.client-view-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.view-list-scroll-400 {
  max-height: 400px;
  overflow: auto;
}

.sales-report-client {
  & .pagination-list li {
    margin: 0;
  }

  & .filter-date {
    display: flex;
    justify-content: flex-end;
    margin: 10px;

    & .date-range-wrapper .filters-date-string {
      text-align: right;
    }
  }
}

.zoom-hover {
  transition: .5s;
  display: inline-block;

  &:hover {
    transform: scale(1.2);
  }
}

.underline-with-color {
  text-decoration: underline;
  text-decoration-color: rgba(15, 111, 255, 0.5);
  text-decoration-thickness: 1px;
}

.fixed-dropdown {
  &.dropdown {
    display: block;
    position: static;

    &.is-right .dropdown-menu {
      top: auto;
      left: auto;
      right: 38px;
    }
  }
}

.align-start {
  align-items: flex-start;
}

.full-size-select__multi-value {
  overflow: visible;
}

.modal .modal-content .with-border-radius  .box {
  border-radius: 12px;
}

.full-width-date {
  & .react-datepicker-wrapper {
    width: 100%;
  }
}
.terminal-wrapper {

}

.terminal-list {
  text-align: left;

  & .img-logo {
    width: 30px;
    height: 30px;
  }

  & .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .wrapper-flex {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.textarea.textarea-chat {
  resize: none;
  height: 100%;
  min-width: 0;
  width: 100%;
  &[rows] {
    height: 100%;
  }
}

@keyframes shrinkAnimation {
  0% {
    max-width: 300px;
    opacity: 1;
  }
  100% {
    max-width: 0;
    opacity: 0;
    display: none;
  }
}

.shrink-animation {
  animation: shrinkAnimation 0.5s ease-in-out forwards;
}

.avatar {
  position: relative;
  width: 45px;
  height: 45px;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.avatar::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}
.avatar.not_read::before {
  content: attr(data-unread);
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  border-radius: 50%;
}
.system-13.avatar::after {
  background-image: url('/img/Telegram_logo.svg');
}

.system-14.avatar::after, .system-facebook.avatar::after {
  background-image: url('/img/Facebook_f_logo.png');
}
.system-17.avatar::after {
  background-image: url('/img/Viber_logo.svg');
}

.system-instagram.avatar::after {
  background-image: url('/img/Instagram_logo.webp');
}

.system-19.avatar::after {
  background-image: url('/img/integrations/19.svg');
}

.timer-wrap {
  margin-left: 10%;
}
.timer {
  font-family:'Open Sans', sans-serif;
  font-size: 11px;
  padding: 3px 8px;
  color: #BBB;
  background-color: #FFF;
  border: 1px solid #E5E5E5;
  border-radius: 15px;
  white-space: nowrap;
}

.integration-block .logo-int {
  max-width: 200px;
  max-height: 40px;
}

.logo-int {
  max-width: 200px;
  max-height: 40px;
}

.statisctic {
  & h1 {
    white-space: nowrap;
  }
}

.full-width-field-flex {

  & .field {
    width: 100%;
    flex: 1;

    &:not(:last-child) {
      margin: 0;
    }
  }
}


.fixed-copy-select__value-container {

  & div {
    width: 100%;
    text-align: left;
  }

  & .fixed-copy-select__placeholder {
    pointer-events: none;
  }

  & .fixed-copy-select__input {
    pointer-events: visible;
    width: 100%;

    & input {
      width: 100% !important;
    }
  }

  & .fixed-copy-select__multi-value__label {
    flex: 1;
  }
  & .fixed-copy-select__multi-value__remove {
    flex: 0;
  }
}

.mySelectProduct__value-container {

  & div {
    width: 100%;
  }

  & .mySelectProduct__placeholder {
    pointer-events: none;
  }

  & .mySelectProduct__input {
    pointer-events: visible;
    width: 100%;

    & input {
      width: 100% !important;
    }
  }

  & .mySelectProduct__multi-value__label {
    flex: 1;
  }
  & .mySelectProduct__multi-value__remove {
    flex: 0;
  }
}

.disabled-arrow-input {
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.disabled-margin-radio {
  & .radio + .radio {
    margin: 0;
  }
}

.dropdown.full-width-dropdown-mobile {

  & .dropdown-item {
    padding: 0
  }

  & .dropdown-item .click_element, .drop-item, .print {
    display: block;
    width: 100%;
    padding: 0.375rem 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    & .dropdown-trigger {
      width: 100%;
    }
    &.is-active .dropdown-menu {
      width: 300px;
      left: 50%;
      right: 50%;
      transform: translate(-50%);

      & .dropdown-item {
        font-size: 1.2em;
        padding: 0
      }

      & .dropdown-item, .click_element, .print {
        display: flex;
        gap: 5px;
        align-items: center;

      }

      & .dropdown-item .click_element, .drop-item, .print {
        width: 100%;
        padding: 10px;
      }
    }
  }
}

.dropdown.full-width-dropdown {
  & .dropdown-item {
    padding: 0
  }
  & .dropdown-item .click_element, .drop-item, .print {
    display: block;
    width: 100%;
    padding: 0.375rem 1rem;
  }
}

.dropdown.dropdown-button {
  text-align: center;
  width: fit-content;

  & .button {
    font-size: inherit;
    background: transparent;
    color: #FFFFFF;
    margin: 0;
  }

  & .icon:last-child:not(:first-child) {
    margin-left: 0;
  }
}

.image-gallery-image {
  min-height: 300px;
}

.services-border {
  border-left: 5px solid #46c37b !important;
}

.bundle-border {
  border-left: 5px solid #ffa43b !important;
}

.max-height-300-scroll-dropdown .dropdown-content {
  max-height: 300px;
  overflow-y: auto;
}

.products-page.fix-box-product {
  @media (min-width: 768px) {
    & table, tbody, td, tr, td {
      height: 100%;
      width: 100%;
    }

    td div.box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.overdue-exp {
  background: #e7c8c8!important;
}

.expires-exp {
  background: #fbf4c8!important;
}

.text-yellow {
  color: #fff700!important;
}

.font-size-18 {
  font-size: 18px;
}
.font-size-20 {
  font-size: 20px;
}

.font-size-07, .heading.font-size-07 {
  font-size: 0.7rem;
}

.link-with-hover {
  color: currentColor;

  &:hover {
    color: #485fc7;
    & b, span {
      color: #485fc7;
    }
  }
}

.disabled_scroll {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-outer-spin-button {
    display: none;
  }

  -webkit-appearance: none;
  appearance: textfield;
}


.notification-13 {
  width: 100%;
  height: 100%;
  background-image: url('/img/Telegram_logo.svg');
}

.notification-14, .notification-facebook {
  width: 100%;
  height: 100%;
  background-image: url('/img/Facebook_f_logo.png');
}

.notification-instagram {
  width: 100%;
  height: 100%;
  background-image: url('/img/Instagram_logo.webp');
}

.date-picker-full-width {
  & .react-datepicker-wrapper {
    width: 100%;
  }
}

.has-text-centered {
  text-align: center;
}

.dropdown-small {
  & .dropdown-trigger button span {
    font-size: 12px;
  }
}

.top-button {
   & .button.is-medium {
     font-size: 1.05rem;
   }

  @media (min-width: 768px) {
    & .dropdown.import-products, .dropdown.export-products, .dropdown.dropdown-button {
       & .dropdown-trigger .button {
         font-size: 1.05rem;
       }
    }

  }
}

.mobile-stock-filter {
  @media (max-width: 768px) {
    position: relative;
    & .control {
      display: flex;
      border-radius: 12px;
      overflow: hidden;
    }

    & .radio {
      flex: 1;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & input[type="radio"] {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }

    & .radio:has(input[type="radio"]:checked) {
      background: #485fc7;
      color: #ffffff;
      &.navkolo{
        background: black;
      }
    }
  }
}


.border-not-top.box {
  @media (max-width: 768px) {
    border-radius: 0 0 12px 12px;
  }
}

@media (max-width: 768px) {
  .modal-start.modal:not(:has(.no-start)) {
    padding-top: 10px;
    justify-content: flex-start;
  }
}

.helpers-block {
  padding: 10px;
  margin: 10px 0;
  border-radius: 12px;
  border: 1px solid #ccc
}

.svg-inline--fa {
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

body .select.select-new {
  & select {
    border: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
    &:hover {
      box-shadow: none;
    }
  }
}


.react-tel-input {
  & .form-control.react-input-phone {
    width: 100%;
    height: 40px;
    border-color: #00d1b2;
    border-radius: 12px;
    font-size: 16px;

    &::placeholder {
      opacity: 30%;
      color: hsl(0, 0%, 21%);
    }
  }
}



.react-input-button {
  &.flag-dropdown, .flag-dropdown.open {
    border-radius: 12px 0 0 12px!important;
    border: 1px solid #00d1b2;
  }

  & .selected-flag.open {
    border-radius: 12px 0 0 12px!important;
  }

  & .selected-flag:hover {
    border-radius: 12px 0 0 12px;
  }
  & .selected-flag:focus {
    border-radius: 12px 0 0 12px;
  }
}
