.wrapper {
    position: fixed;
    top: 60px;
    right: 13px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: fit-content;
    max-height: calc(100vh - 60px);
    //overflow: auto;
}

.popup {
    min-width: 280px;
    max-width: 280px;
    text-align: left;
    display: flex;
    gap: 5px;
    align-items: flex-start;
    flex-direction: column;
    background: #ffffff;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 rgba(34, 60, 80, 0.2);
    position: relative;
    opacity: 0;
    cursor: pointer;
    transition: 1s;

    &.visible_close {
        transform: translateX(0);
        animation: slideOut 0.5s forwards;
    }
    &.visible {
        transform: translateX(293px);
        animation: slideIn 0.5s forwards;
    }
}

.name_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-right: 10px;
}

.icon_name {
    width: 16px;
    min-width: 16px;
    height: 16px;
}

.name {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.message {
        overflow-wrap: break-word;
}

.close {
    position: absolute;
    right: 6px;
    top: 3px;
    color: red;
    cursor: pointer;

    &:hover {
        color: #ac0000;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(293px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(293px);
        opacity: 0;
    }
}