@charset "utf-8";
$clean-white: #ffffff;

@media screen and (max-width: 767px) {
    .account-logo {
      margin-top: 5px;
    }

    html {
    overflow-x: visible;
    }

    body {
        overflow-x: auto;
    }

    .box {
        background-color: $clean-white;
    }

    .mobile .sales-report td {
        padding-bottom: 10px;
    }
}

